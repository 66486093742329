import * as $ from 'jquery';
import {KeyValuePairInterface} from '../interfaces/key-value-pair.interface';

/**
 * Facilitates dispatching and listening for events
 */
export class EventDispatcher {
    opts: KeyValuePairInterface;
    _jObj: JQuery;
    eventListeners: KeyValuePairInterface;

    /**
     * @constructor
     * @param {Object} options
     */
    constructor(options) {

        this.opts = $.extend({}, options || {});

        // _jObj : used for firing events. In jQuery
        // "$(this).on..." works for plain JS objects,
        // but doesn't work correctly for class instances, so here,
        // create and maintain a simple jQuery obj for event dispatch
        this._jObj = $('<div></div>');

        this.eventListeners = {};

        for (const q in this.opts) {
            if (this.opts.hasOwnProperty(q) && typeof (this.opts[q]) === 'function' && /^on[A-Z]/.test(q)) {
                const e = q.charAt(2).toLowerCase() + q.substring(3);
                this.addEventListener(e, this.opts[q]);
                delete this.opts[q];
            }
        }
    }

    /**
     * @param {string} evtName
     */
    hasEventListener(evtName): boolean {
        return !!this.eventListeners[evtName];
    }

    /**
     * Destroy; should be called via super from destroy method of all subclasses.
     * Un-registers any event listeners and performs additional cleanup.
     *
     * @destruct
     */
    destroy(): void {
        if (this.opts) {
            for (const q in this.opts) {
                if (this.opts.hasOwnProperty(q)) {
                    this.opts[q] = null;
                }
            }
        }
        if (this._jObj) {
            this._jObj.off();
            this._jObj = null;
        }
    }

    /**
     * Subscribe a listener to the supplied event
     *
     * @param {string}   evtName event name
     * @param {Function} func    handler for event
     */
    addEventListener(evtName: string, func: (event: JQuery.Event, data: any) => any) {
        if (evtName === '' || typeof evtName !== 'string') {
            throw new Error('EventDispatcher: invalid event name passed to addEventListener');
        }
        if (this._jObj) {
            this._jObj.on(evtName, func);
            if (!this.eventListeners[evtName]) {
                this.eventListeners[evtName] = 0;
            }
            this.eventListeners[evtName]++;
        }
    }

    /**
     * Un-subscribe a listener to the supplied event
     *
     * @param {String}   evtName event name
     * @param {Function|null} func    handler for event
     */
    removeEventListener(evtName: string, func: (event: JQuery.Event) => any = null) {
        if (this._jObj) {
            this._jObj.off(evtName, func);
            if (!func) {
                this.eventListeners[evtName] && delete this.eventListeners[evtName];
            } else {
                this.eventListeners[evtName] && this.eventListeners[evtName]--;
            }
        }
    }

    /**
     * Dispatches an event of the supplied name, passing optional data as a 2nd arg to the listener
     *
     * @param {String} evtName event name
     * @param {Object} data
     */
    dispatchEvent(evtName: string, data: any = null) {
        const d = data !== undefined && data !== null && data.eventData !== undefined ? data
            : {eventData: data === 0 ? data : (data || null)};

        if (!Array.isArray(data)) {
            data = [data];
        }

        if (this._jObj) {
            const e = new $.Event(evtName, $.extend(true, d, {target: this}));
            this._jObj.trigger(e, data || []);
        }
    }

}