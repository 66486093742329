import {EventDispatcher} from '../../event-dispatcher/event-dispatcher';
import {LiveCoverageClientInterface} from '../../interfaces/live-coverage-client.interface';
import * as $ from 'jquery';
import {PollingClientOptionsInterface} from '../../interfaces/polling-client-options.interface';

/**
 * Class to facilitate polling the cms api and dispatching a renderItems event
 */
export class PollingClient extends EventDispatcher implements LiveCoverageClientInterface {
    interval: any;

    /**
     * @constructor
     * @param {{}} options
     */
    constructor(options: PollingClientOptionsInterface) {
        super(options);
        this.opts = $.extend(true, {
            updateLimit: 180,
        }, this.opts);

        if (this.isPastUpdateLimit()) {
            return;
        }

        this.interval = setInterval(() => {
            this.initClient();
        }, this.opts.pollingInterval);
    }

    /**
     * @inheritDoc
     */
    initClient() {
        const firstItem = this.getFirstItem();
        let sequence = 0;
        if (firstItem) {
            sequence = parseInt(firstItem.dataset.sequence) + 1;
        }
        $.ajax({
            method: 'GET',
            url: `${this.opts.pollingUrl}?start=${sequence}`,
            contentType: 'application/json',
            success: (response) => {
                this.dispatchEvent('renderItems', [response]);
            },
        });
    }

    /**
     * Destroys the event dispatcher events and clears the polling interval
     */
    destroy(): void {
        super.destroy();
        clearInterval(this.interval);
        this.interval = null;
    }

    /**
     * Checks to see if the first item is older than the specified updateLimit
     * @returns {boolean}
     */
    isPastUpdateLimit(): boolean {
        const limitDate = new Date();
        const firstItem = this.getFirstItem();
        if (firstItem) {
            const firstItemDate = new Date(firstItem.querySelector('.live-coverage-timestamp').textContent.trim());
            limitDate.setMinutes(-(this.opts.updateLimit));

            return limitDate > firstItemDate;
        }

        return false;
    }

    /**
     * Gets the first live-coverage-item
     * @returns {HTMLElement}
     */
    private getFirstItem(): HTMLElement {
        return document.querySelector('.live-coverage-item');
    }
}