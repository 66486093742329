import {LiveCoverageItemInterface} from '../../interfaces/live-coverage-item.interface';
import '../../utils/date-prototype.utils';
import {LiveCoverageConfigurationInterface} from "../../interfaces/live-coverage-configuration.interface";

declare var liveCoverage: LiveCoverageConfigurationInterface;

/**
 * Base implementation of a renderer for live coverage items
 */
export class BaseLiveCoverageItem {
    id: string;
    parentId: string;
    type: string;
    namespace: string;
    view: any;
    sequence: string;
    datePublished: number;
    dateUpdated: number;
    pinned: boolean;
    body: HTMLElement;
    dateFormat: string = liveCoverage.dateFormat;

    /**
     * @constructor
     * @param {LiveCoverageItemInterface} item
     */
    constructor(item: LiveCoverageItemInterface) {
        const template = document.createElement('template');
        template.innerHTML = item.body;
        if (template.content.firstElementChild) {
            this.body = <HTMLElement>template.content.firstElementChild
        }
        else {
            this.body = document.createElement('div');
            this.body.append(template.textContent);
        }
        this.id = item.id;
        this.parentId = item.parentId;
        this.type = item.type;
        this.namespace = item.namespace;
        this.view = item.view;
        this.sequence = item.sequence;
        this.datePublished = item.datePublished;
        this.dateUpdated = item.dateUpdated;
        this.pinned = item.pinned;
    }

    /**
     * Turns the millisecond timestamp into a formatted string and outputs a div
     */
    getTimestamp() {
        const timestampDiv = this.body.querySelector('.live-coverage-timestamp');
        if (timestampDiv) {
            timestampDiv.innerHTML = this.getDateFromSeconds(this.datePublished).format(this.dateFormat);
        }
    }

    /**
     * Gets the dates from seconds
     * @param {number} seconds
     * @returns {Date}
     */
    getDateFromSeconds(seconds: number): Date {
        const date = new Date();
        date.setTime(seconds * 1000);

        return date;
    }

    /**
     * Renders the root node of live coverage item DOM
     * @returns {HTMLElement}
     */
    renderItemContainer() {
        const itemClass = ['live-coverage-item'];

        this.body.setAttribute('data-id', this.id);
        this.body.setAttribute('data-sequence', this.sequence);
        if (liveCoverage.itemClass) {
            itemClass.push(liveCoverage.itemClass);
        }
        this.body.className = itemClass.join(' ');
        this.body.dataset.sequence = this.sequence;
    }

    /**
     * Renders the live coverage item for the page
     * @returns {HTMLElement}
     */
    renderItem(): HTMLElement{
        this.renderItemContainer();
        this.getTimestamp();

        return this.body;
    };
}