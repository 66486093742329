import {BaseFlyClient} from '../base/base-fly-client';
import {ChannelInterface} from '../../interfaces/channel.interface';
import {BaseLiveCoverageItem} from '../../live-coverage-item/base/base-live-coverage-item';
import {LiveCoverageItemInterface} from "../../interfaces/live-coverage-item.interface";

/**
 * Client for the live coverage content types. Facilitates connecting to the cms to get data and rendering items returned
 */
export class LiveCoverageClient extends BaseFlyClient {

    /**
     * @inheritDoc
     */
    constructor(contentType:string = null, hostname: string = null, port: number = null, channels: ChannelInterface[] = null, mode: string = null, pollingInterval: number = null) {
        super(contentType, hostname, port, channels, mode, pollingInterval);

        this.createClient();
    }

    /**
     * @inheritDoc
     */
    populateItems(): void {
        const elements = document.querySelectorAll('.live-coverage-item');
        elements.forEach((element: HTMLElement) => {
            const sequence = element.dataset.sequence;

            this.liveCoverageItems[sequence] = new BaseLiveCoverageItem({
                sequence: sequence,
                body: element.querySelector('.live-coverage-body').textContent.trim(),
            });
        });
    }

    /**
     * @inheritDoc
     */
    renderItem(item): HTMLElement {
        const liveCoverageItem = new BaseLiveCoverageItem(item);

        return liveCoverageItem.renderItem();
    }
}
