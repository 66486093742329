import {LiveCoverageClientInterface} from '../../interfaces/live-coverage-client.interface';
import {PollingClient} from '../poll-client/polling-client';
import {EventDispatcher} from '../../event-dispatcher/event-dispatcher';
import {SocketClusterClient} from '../socket-cluster/socket-cluster-client';
import {FallbackClientOptionsInterface} from '../../interfaces/fallback-client-options.interface';
import * as $ from 'jquery';

/**
 * Facilitates the combined mode. Defaults to the websocket interface and falls back to the polling client.
 * The websocket will try to reconnect. In this case we will discontinue the polling client.
 */
export class FallbackClient extends EventDispatcher implements LiveCoverageClientInterface {
    socketClusterClient: SocketClusterClient;
    pollingClient: PollingClient;

    /**
     * @constructor
     * @param options
     */
    constructor(options: FallbackClientOptionsInterface) {
        super(options);
        const pastSocketLimit = this.isPastSocketUpdateLimit();
        const pastPollLimit = this.isPastPollingUpdateLimit();
        if (pastSocketLimit && pastPollLimit) {
            return;
        } else if (pastSocketLimit && !pastPollLimit) {
            this.doPollingClient();
            return;
        }
        this.initClient();
    }

    /**
     * Attempts to initialize the websocket interface. If that fails it will revert to polling
     */
    initClient(): void {
        if (!this.socketClusterClient) {
            this.doWebSocketClient();
        }
    }

    /**
     * Creates the web socket client and on error it will initialize the polling client. On reconnect it will kill the polling client
     */
    doWebSocketClient() {
        if (!this.socketClusterClient) {
            this.socketClusterClient = new SocketClusterClient(
                $.extend(
                    true,
                    {
                        updateLimit: this.opts.socketServerUpdateLimit,
                    },
                    this.opts));
            this.socketClusterClient.addEventListener('renderItems', (event, items) => {
                this.dispatchEvent('renderItems', [items]);
            });

            this.socketClusterClient.addEventListener('connect', () => {
                const firstItem = this.getFirstItem();
                let sequence = 0;
                if (firstItem) {
                    sequence = parseInt(firstItem.dataset.sequence) + 1;
                }
                this.socketClusterClient.opts.sequence = sequence;
                if (this.pollingClient) {
                    this.pollingClient.destroy();
                    this.pollingClient = null;
                }
            });

            this.socketClusterClient.addEventListener('error', () => {
                this.socketClusterClient.destroy();
                this.socketClusterClient.removeEventListener('renderItems', () => null);
                this.socketClusterClient = null;
                this.doPollingClient();
            });
        }
    }

    /**
     * Initializes the polling client
     */
    doPollingClient() {
        if (!this.pollingClient) {
            this.pollingClient = new PollingClient({
                pollingUrl: `/${this.opts.contentType}/render/${this.opts.contentId}/item/range`,
                pollingInterval: this.opts.pollingInterval,
                updateLimit: this.opts.pollingUpdateLimit,
            });
            this.pollingClient.addEventListener('renderItems', (event, items) => {
                this.dispatchEvent('renderItems', [items]);
            });
        }
    }

    /**
     * Checks to see if the first item is older than the specified socketServerUpdateLimit
     * @returns {boolean}
     */
    isPastSocketUpdateLimit(): boolean {
        const limitDate = new Date();
        const firstItem = this.getFirstItem();
        if (firstItem) {
            const firstItemDate = new Date(firstItem.querySelector('.live-coverage-timestamp').textContent.trim());
            limitDate.setMinutes(-(this.opts.socketServerUpdateLimit));

            return limitDate > firstItemDate;
        }

        return false;
    }

    /**
     * Checks to see if the first item is older than the specified pollingUpdateLimit
     * @returns {boolean}
     */
    isPastPollingUpdateLimit(): boolean {
        const limitDate = new Date();
        const firstItem = this.getFirstItem();
        if (firstItem) {
            const firstItemDate = new Date(firstItem.querySelector('.live-coverage-timestamp').textContent.trim());
            limitDate.setMinutes(-(this.opts.pollingUpdateLimit));

            return limitDate > firstItemDate;
        }

        return false;
    }

    /**
     * Gets the first live-coverage-item
     * @returns {HTMLElement}
     */
    private getFirstItem(): HTMLElement {
        return document.querySelector('.live-coverage-item');
    }
}
