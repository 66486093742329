import * as socketClusterClient from 'socketcluster-client';
import * as $ from 'jquery';
import {EventDispatcher} from '../../event-dispatcher/event-dispatcher';
import {LiveCoverageConfigurationInterface} from '../../interfaces/live-coverage-configuration.interface';
import {LiveCoverageClientInterface} from '../../interfaces/live-coverage-client.interface';
import {SocketServerClientOptionsInterface} from '../../interfaces/socket-server-client-options.interface';
import {ChannelConfigInterface} from '../../interfaces/channel-config.interface';

declare var liveCoverage: LiveCoverageConfigurationInterface;

/**
 * Facilitates talking to the socket cluster and receiving updates. Dispatches events so the fly clients know what is going on
 */
export class SocketClusterClient extends EventDispatcher implements LiveCoverageClientInterface {
    opts: SocketServerClientOptionsInterface;
    client: socketClusterClient.SCClientSocket;
    channelConfig: ChannelConfigInterface = {};
    channels: {} = {};
    pendingSubscribeChannels: string[] = [];
    channelsSet: boolean;

    /**
     * @constructor
     * @param {SocketServerClientOptionsInterface} options
     */
    constructor(options: SocketServerClientOptionsInterface) {
        super(options);

        this.opts = $.extend(true, {
            hostname: null,
            port: null,
            channels: [],
            contentId: null,
            view: null,
            sequence: null,
            secure: true,
            updateLimit: 60,
        }, options);

        for (const c in this.opts.channels) {
            const channel = this.opts.channels[c];
            this.channels[channel.name] = null;
            this.channelConfig[channel.name] = channel;
        }

        if (this.isPastUpdateLimit()) {
            return;
        }

        this.initClient();
    }

    /**
     * Subscribes to the specified channel or adds it to the pending array if the client is not initialized
     *
     * @param {string} channelName
     */
    subscribe(channelName: string): void {
        const channel = this.channelConfig[channelName];
        if (channel && channel.key) {
            if (!this.channels[channelName]) {

                if (!this.channelsSet) {
                    this.initClient();
                    this.pendingSubscribeChannels.push(channelName);

                    return;
                }

                this.channels[channelName] = this.client.subscribe(channelName, {waitForAuth: true});
                this.channels[channelName].watch((data) => {
                    if(Array.isArray(data) && typeof data[0] === 'string'){
                        data = data.map((item)=>{return JSON.parse(item)});
                    }

                    this.dispatchEvent('renderItems', [data]);
                });
                this.dispatchEvent('subscribed');
            }
        }
    }

    /**
     * @inheritDoc
     */
    initClient(): void {
        if (!this.client) {
            this.client = socketClusterClient.create({
                hostname: this.opts.hostname,
                port: this.opts.port,
                secure: this.opts.secure

            });
            this.client.on('connect', (data) => {
                this.dispatchEvent('connect');
                this.client.emit('set_channels', {channels: this.opts.channels});
            });
            this.client.on('channels_set', () => {
                this.client.on('live_coverage_items', (data) => {
                    if(Array.isArray(data) && typeof data[0] === 'string'){
                        data = data.map((item)=>{return JSON.parse(item)});
                    }
                    this.dispatchEvent('renderItems', [data]);
                    this.subscribe(`${liveCoverage.namespace}.${this.opts.contentId}.${this.opts.view}`);

                });
                this.client.emit('live_coverage_items', {
                    parentId: this.opts.contentId,
                    namespace: liveCoverage.namespace,
                    view: this.opts.view,
                    sequence: this.opts.sequence,
                });
                this.channelsSet = true;
                for (const channel in this.pendingSubscribeChannels) {
                    this.subscribe(this.pendingSubscribeChannels[channel]);
                }
            });
            this.client.on('error', (error) => {
                this.dispatchEvent('error', {error});
            });
        }
    }

    /**
     * Checks to see if the first item is older than the specified updateLimit
     * @returns {boolean}
     */
    isPastUpdateLimit(): boolean {
        const limitDate = new Date();
        const firstItem = this.getFirstItem();
        if (firstItem) {
            const firstItemDate = new Date(firstItem.querySelector('.live-coverage-timestamp').textContent.trim());
            limitDate.setMinutes(-(this.opts.updateLimit));

            return limitDate > firstItemDate;
        }

        return false;
    }

    /**
     * Gets the first live-coverage-item
     * @returns {HTMLElement}
     */
    private getFirstItem() {
        return document.querySelector('.live-coverage-item');
    }
}