import {SocketClusterClient} from '../../cms-clients/socket-cluster/socket-cluster-client';
import {PollingClient} from '../../cms-clients/poll-client/polling-client';
import {FallbackClient} from '../../cms-clients/fallback-client/fallback-client';
import {ChannelInterface} from '../../interfaces/channel.interface';
import {LiveCoverageItemInterface} from '../../interfaces/live-coverage-item.interface';
import {LiveCoverageConfigurationInterface} from '../../interfaces/live-coverage-configuration.interface';
import '../../utils/date-prototype.utils';
import {BaseLiveCoverageItem} from '../../live-coverage-item/base/base-live-coverage-item';

declare var liveCoverage: LiveCoverageConfigurationInterface;

/**
 * Abstract class to serve as a starting point for any live coverage content type clients
 */
export abstract class BaseFlyClient {
    socketClusterClient: SocketClusterClient;
    pollingClient: PollingClient;
    fallbackClient: FallbackClient;
    contentType: string = liveCoverage.contentType;
    hostname: string = liveCoverage.socketServerHostname;
    port: number = liveCoverage.socketServerPort;
    mode: string = liveCoverage.mode;
    device: string = liveCoverage.device;
    pollingInterval: number = liveCoverage.pollingInterval;
    pollingUrl: string = liveCoverage.pollingUrl;
    pollingUpdateLimit: number = parseInt(liveCoverage.pollingUpdateLimit);
    socketServerUpdateLimit: number = parseInt(liveCoverage.socketServerUpdateLimit);
    channels: ChannelInterface[];
    contentId: string = liveCoverage.objectId;
    dateFormat: string = liveCoverage.dateFormat;
    liveCoverageItems: { [key: number]: BaseLiveCoverageItem } = [];
    itemsRenderedCallback: Function = liveCoverage.itemsRenderedCallback;

    /**
     * @constructor
     * @param {string} contentType
     * @param {string} hostname
     * @param {number} port
     * @param {ChannelInterface[]} channels
     * @param {string} mode
     * @param {number} pollingInterval
     */
    constructor(contentType:string = null, hostname: string = null, port: number = null, channels: ChannelInterface[] = null, mode: string = null, pollingInterval: number = null) {
        this.contentType = contentType;
        this.hostname = hostname || this.hostname;
        this.port = port || this.port;
        this.channels = channels || this.channels;
        this.mode = mode || this.mode;
        this.pollingInterval = pollingInterval || this.pollingInterval;
        this.populateItems();
        this.fixTimestamps();
    }

    /**
     * Creates the client to talk to the cms based on the mode
     */
    createClient() {
        switch (this.mode) {
            case 'polling':
                this.createPollingClient();
                break;
            case 'combined':
                this.createFallbackClient();
                break;
            case 'web_socket':
            default:
                this.createSocketClient();
                break;
        }
    }

    /**
     * Creates the FallBack client for combined mode
     */
    createFallbackClient(): void {
        if (!this.fallbackClient) {
            const firstItem: HTMLElement = document.querySelector('.live-coverage-item');
            this.channels = [
                {key: liveCoverage.channelKey, name: liveCoverage.channelName},
            ];
            let sequence = 0;
            if (firstItem) {
                sequence = parseInt(firstItem.dataset.sequence) + 1;
            }

            this.fallbackClient = new FallbackClient({
                hostname: this.hostname,
                port: this.port,
                channels: this.channels,
                view: this.device,
                sequence: sequence,
                contentId: this.contentId,
                pollingUrl: this.pollingUrl,
                pollingInterval: this.pollingInterval,
                contentType: this.contentType,
                socketServerUpdateLimit: this.socketServerUpdateLimit,
                pollingUpdateLimit: this.pollingUpdateLimit,
            });
            this.fallbackClient.addEventListener('renderItems', (event: JQuery.Event, items: LiveCoverageItemInterface[]) => {
                this.renderItems(event, items);
            });
        }
    }

    /**
     * Create te polling client
     */
    createPollingClient(): void {
        if (!this.pollingClient) {
            this.pollingClient = new PollingClient({
                pollingUrl: this.pollingUrl,
                pollingInterval: this.pollingInterval,
                updateLimit: this.pollingUpdateLimit || 180,
            });
            this.pollingClient.addEventListener('renderItems', (event: JQuery.Event, items: LiveCoverageItemInterface[]) => {
                this.renderItems(event, items);
            });
        }
    }

    /**
     * Creates the web socket client
     */
    createSocketClient(): void {
        if (!this.socketClusterClient) {
            const firstItem: HTMLElement = document.querySelector('.live-coverage-item');
            let sequence = 0;
            if (firstItem) {
                sequence = parseInt(firstItem.dataset.sequence) + 1;
            }
            this.channels = [
                {key: liveCoverage.channelKey, name: liveCoverage.channelName},
            ];
            this.socketClusterClient = new SocketClusterClient({
                hostname: this.hostname,
                port: this.port,
                channels: this.channels,
                view: this.device,
                secure: true,
                sequence: sequence,
                contentId: this.contentId,
                updateLimit: this.socketServerUpdateLimit,
            });
            this.socketClusterClient.addEventListener('renderItems', (event: JQuery.Event, items: LiveCoverageItemInterface[]) => {
                this.renderItems(event, items);
            });
        }
    }
    
    /**
     * Takes in an array of LiveCoverage items and renders them on the page
     * @param {JQuery.Event}event
     * @param {LiveCoverageItemInterface[]}items
     */
    /**
     * @inheritDoc
     */
    renderItems(event: JQuery.Event, items: LiveCoverageItemInterface[] | LiveCoverageItemInterface): void {
        if (!Array.isArray(items)) {
            items = [items];
        }

        items.forEach((item) => {
            this.processItem(item);
        });

        this.itemsRenderedCallback && this.itemsRenderedCallback(items);
    }

    /**
     * Adds, updates, or deletes item depending on item state.
     *
     * @param {LiveCoverageItemInterface} item
     */
    processItem(item: LiveCoverageItemInterface): void {
        if (item.deleted) {
            this.deleteItem(item);
        } else {
            const existingChild: HTMLElement = document.querySelector(`.live-coverage-items-container > [data-id="${item.id}"]`);
            if (existingChild) {
                existingChild.replaceWith(this.renderItem(item));
            } else {
                this.addItem(item);
            }
        }
    }

    /**
     * Adds new item to DOM.
     *
     * @param {LiveCoverageItemInterface} item
     */
    addItem(item: LiveCoverageItemInterface): void {
        const container = document.querySelector('.live-coverage-items-container');
        const firstChild: HTMLElement = container.querySelector('.live-coverage-item');
        
        if (!firstChild || item.sequence > firstChild.dataset.sequence) {
            container.insertBefore(this.renderItem(item), firstChild);
            this.liveCoverageItems[item.sequence] = item;
        }
    }

    /**
     * Remove items flagged for deletion from DOM.
     *
     * @param {LiveCoverageItemInterface} item
     */
    deleteItem(item: LiveCoverageItemInterface): void {
        const container = document.querySelector('.live-coverage-items-container');
        const itemElem: HTMLElement = container.querySelector(`[data-id="${item.id}"]`);
        itemElem && itemElem.remove();
    }
    
    /**
     * Fixes the timestamps of the items on the page at page load. Twig doesn't have access to users current timezone so we fix it here
     */
    fixTimestamps(): void {
        const items = document.querySelectorAll('.live-coverage-item');
        items.forEach((element: HTMLElement) => {
            const timestamp = element.querySelector('.live-coverage-timestamp');
            if (timestamp) {
                const date = new Date(`${timestamp.textContent} UTC`);
                timestamp.textContent = date.format(this.dateFormat);
            }
        });
    }

    /**
     * Populates the liveCoverageItems object with the items on the page on pageload
     */
    abstract populateItems(): void;

    /**
     * Takes in a live coverage item and renders it
     * @param {LiveCoverageItemInterface} item
     * 
     * @returns HTMLElement
     */
    abstract renderItem(item: LiveCoverageItemInterface): HTMLElement;
}